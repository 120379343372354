$breakpoints: (
    // 'smPhone': 349px,
    // 'phone': 449px, 
    'smTablet': 619px,
    'tablet': 989px,
    'desktop': 1365px,
);
@mixin mq($width) {
    @if map_has_key($breakpoints, $width) {
        $width: map_get($breakpoints, $width);
        @media only screen and (min-width: $width) {
            @content;
        }
    }
}