.landing-page {

  &__referral {
    display: grid;
    color: #fff;
    grid-template-columns: 1fr;
    background: #3e3e3e;
    padding: 1rem;
    justify-items: center;
    gap: .5rem;
    // margin-bottom: 2rem;
    

    @include mq('tablet') {
      grid-column: 1/3;  
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

  &__header {
    padding: 1rem 2rem;
    border-bottom: 1px solid #3e3e3e50;

    & img {
      width: 20rem;
      display: block;
    }

    &--actions {
      display: none;

      // @include mq('tablet') {
      //   justify-self: end;
      //   align-self: center;
      // }
    }

    @include mq('tablet') {     
      display: grid;
      grid-template-columns: 1fr 5fr;
  
      &--actions {
        justify-self: end;
        align-self: center;
        display: block;

        & a {
          text-decoration: none;
          font-size: 1.4rem;
          font-weight: bold;
          margin: 0 1rem;
          color: #3e3e3e;
  
          &:visited {
            color: #3e3e3e;
          }
  
          &:hover {
            color: #555555
          }
        }
      }
    }
  }

  &__hero {
    // background: 
    background-image: linear-gradient(#fff, #2929293a), url("https://getmyblue-assets.s3-us-west-2.amazonaws.com/bluenumberhero.jpg");
    background-size: cover;
    background-position-y: center;
    padding: 2rem;
    
    @include mq('smTablet') {
      padding: 4rem 5rem;
      background-image: linear-gradient(#ffffffcc, #8181813a), url("https://getmyblue-assets.s3-us-west-2.amazonaws.com/bluenumberhero.jpg");
    }
    
    @include mq('tablet') {
      padding: 6rem 12rem 14rem;
    }
    
    @include mq('desktop') {
      background-image: url("https://getmyblue-assets.s3-us-west-2.amazonaws.com/bluenumberhero.jpg");
    }

    & h1 {
      color: #0665BD;
      font-size: 4.2rem;
      padding-bottom: 6rem;
      text-shadow: 1px 2px #fff;
      
      @include mq('smTablet') {
        padding-bottom: 0;
      }
      
      @include mq('desktop') {
        font-weight: lighter;
        text-shadow: unset;
      }
    }

    & h4 {
      margin-top: 1.5remrem;
      font-size: 24px;
      color: #0665BD;
      display: none;
      text-shadow: 1px 2px #fff;
      
      @include mq('smTablet') {
        display: block;
      }
      
      @include mq('desktop') {
        font-weight: lighter;
        text-shadow: unset;
      }
    }

    @include mq('smDesktop') {
      padding: 10rem;
      padding-bottom: 6rem;
  
      & h1 {
        font-size: 48px;
      }

      & h4 {
        margin-top: 0;
      }
    }
  }
}

.schedule {
  background: #0665BD;
  color: #fff !important;
  font-size: 1.4rem;
  margin-right: 3rem;
  font-weight: lighter;
  padding: 1rem 2rem;
  border-radius: 2rem;

  &:hover {
    background: lighten(#0665BD, 10%);
  }

  &:visited {
    color: #fff !important;
  }
}

.lp-section-one {
  position: relative;
  display: grid;
  overflow-x: hidden;
  min-height: 550px;

  @include mq("tablet") {
    position: relative;
    margin-top: 0;
    overflow-x: unset;
    grid-template-columns: 1fr 1fr;
  }

  & ol {
    margin-left: 3rem;

    & li {
      margin: 1.5rem 1rem;
      line-height: 1.35em;
      font-size: 2rem;

      @include mq('tablet') {
        font-size: 2.7rem;
        font-weight: lighter;
      }
    }
  }

  &__heading {
    align-self: center;
    justify-self: center;
    margin: 2rem;

    @include mq('tablet') {
      margin: unset;
      align-self: center;
      justify-self: center;
      grid-column: 1/3;
    }

    & h2 {
      color: $tranont-primary;
      font-weight: lighter;
      font-size: 3.2rem;
      line-height: 4rem;
      text-align: center;
      margin: 0 2rem;
    }
  }

  &__content {
    margin-top: 2rem;
    padding-left: 2rem;
    
    @include mq("smTablet") {
      justify-self: center;
      padding-left: 5rem;
    }

    @include mq("tablet") {
      z-index: 2;
      position: relative;
      align-self: center;
      
      & h2 {
        padding-top: 5rem;
        font-weight: lighter;
        font-size: 32px;
        text-align: center;
      }

      & h4 {
        font-size: 3.2rem;
      }
  
      &--left {
        width: 37vw;
        margin-top: 6rem;
      }
    }
  }

  &__layer-one {
    position: absolute;
    right: 0rem;
    top: 9rem;
    z-index: -1;
    width: 31rem;

    @include mq("tablet") {
      right: 0;
      top: -25rem;
      width: 52rem;
    }
  }

  &__layer-two {
    position: absolute;
    z-index: -1;
    right: -5rem;
    top: 30rem;
    width: 15rem;
    
    @include mq("tablet") {
      right: 0;
      top: -10rem;
      width: 20rem;
      z-index: 0;
    }
  }

  &__layer-three {
    display: none;
    grid-template-columns: repeat(7, 8px);
    grid-gap: 4rem;
    gap: 4rem;
    position: absolute;
    right: 12rem;
    top: 20rem;
    z-index: -1;

    @include mq("tablet") {
      display: grid;
    }
  }

  &__layer-four {
    grid-row: 2;

    @include mq("tablet") {
      position: absolute;
      right: 19rem;
      top: 10rem;
      z-index: 1;
    }

    @include mq("desktop") {
      top: 7rem;
    }

    & img {
      width: 28rem;
      border-radius: 3rem;
      box-shadow: 0px 4px 36px rgba(0, 0, 0, 0.12);
      margin: 0 auto;
      display: block;

      @include mq("smTablet") {
        width: 36rem;
      }

      @include mq("desktop") {
        width: 42rem;
      }
    }
  }
}

.lp-section-two {
  position: relative;
  height: 120rem;
  
  @include mq("smTablet") {
    height: 115rem;
  }
  
  @include mq("tablet") {
    height: 105rem;
  }
  
  @include mq("desktop") {
    height: 90rem;
  }

  & h4 {
    margin-top: 2rem;
  }

  & ul {
    margin: 2rem;

    & li {
      margin: 1.5rem 1rem;
      line-height: 1.35em;
      font-size: 2rem;

      @include mq('tablet') {
        font-size: 2.7rem;
        font-weight: lighter;
      }
    }
  }

  &__layer-one {
    height: 50rem;

    @include mq('tablet') {
      height: 70rem;
      position: absolute;
      top: 100px;
    }
  }

  &__layer-two {
    position: absolute;
    top: 5rem;

    @include mq("smTablet") {
      left: 50%;
      transform: translate(-50%);
      width: 63.3rem;

      @include mq('tablet') {
        width: 85%;
      }
    }
  }

  &__layer-three {
    position: absolute;
    right: 0;
    top: 60rem;
    z-index: -1;
    max-height: 912px;

    @include mq("smTablet") {
      display: none;
    }
  }

  &__content {
    z-index: 2;
    display: grid;
    grid-template-columns: 1fr;

    @include mq("tablet") {
      grid-template-columns: 1fr 1fr;
      margin: 8rem 0;
      align-items: center;
      font-weight: 1.8rem;

      & h4 {
        font-size: 3.2rem;
      }
    }

    & img {
      width: 27rem;
      border-radius: 3rem;
      box-shadow: 0px 4px 36px rgba(0, 0, 0, 0.12);
      padding: 1rem;
      background: #fff;
      margin: 0 auto;
      grid-row: 1;
      
      @include mq("smTablet") {
        width: 36rem;
      }
      
      @include mq("tablet") {
        margin: 0;
        width: 45rem;
      }
    }

    &--text {
      margin: 2rem;
    }

    & .right-img {
      padding: 0;

      @include mq('tablet') {
        grid-column: 2;
        justify-self: end;
      }
    }
  }
}

.lp-section-three {
  position: relative;
  height: 55rem;
  overflow: hidden;

  & h2 {
    font-size: 4rem;
    font-weight: lighter;
    margin-left: 2rem;

    @include mq('tablet') {
      margin-left: 12rem;
    }
  }

  &__layer-one {
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    bottom: -25rem;
    z-index: -1;
  }

  &__layer-two {
    display: grid;
    grid-template-columns: repeat(8, 8px);
    gap: 4rem;
    position: absolute;
    left: 10.1rem;
    top: 13rem;
    transform: rotate(45deg);
    z-index: -1;
    
    @include mq("smTablet") {
      right: 20%;
      left: unset;
    }
    
    @include mq('tablet') {
      transform: unset;
    }
  }

  &__layer-three {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    
    .testimonial-container {
      display: grid;
      grid-template-columns: 1fr;

      @include mq('tablet') {
        grid-template-columns: 1fr 1fr;
        gap: 20rem;
      }
    }

    & .testimonial-widget {
      display: grid;
      grid-template-columns: 6rem 1fr;
      width: 24rem;
      background: #fff;
      margin: 3rem;
      border-radius: 1rem;
      box-shadow: 0px 4px 36px rgba(0, 0, 0, 0.12);
      padding: 1rem;
      cursor: pointer;
      align-items: center;
      gap: 2rem;

      &__initials {
        display: grid;
        align-items: center;
        justify-items: center;
        background: linear-gradient(144.16deg, #79BFFF -1.41%, #0665BD 103.04%);
        color: #fff;
        height: 3.5rem;
        width: 3.5rem;
        padding: .5rem;
        border-radius: .5rem;
      }

      &__info {
        &--name {
          color: #0665BD;
          font-size: 1.8rem;
        }

        &--location {
          font-size: 1.4rem;
          color: #6c6c6c;
        }
      }
    }

    & .testimonial {
      background: #fff;
      box-shadow: 0px 4px 36px rgba(0, 0, 0, 0.12);
      padding: 5rem;
      border-radius: 1.6rem;
      height: fit-content;
      height: -moz-fit-content;
      align-self: center;
      width: 30rem;

    }
  }
}

.lp-final-cta {
  background: linear-gradient(251.72deg, #79BFFF -2.69%, #0665BD 92.66%);
  margin-top: 0;
  padding: 5rem 1rem;
  // height: 40rem;
  
  & h1 {
    font-size: 32px;
    font-weight: lighter;
    color: #fff;
    text-align: center;
  }

  @include mq('smDesktop') {
    height: 20rem;
    padding-bottom: 6rem; 
    
    & h1 {
      font-size: 32px;
      font-weight: lighter;
      color: #fff;
      text-align: center;
    }
  }
}

.lp-final-cta.last {
  text-align:center;
  background:white;
  padding:1rem;
  & a {
    margin:5rem auto;
  }
}

.right {
  justify-self: end;
}

.testimonials-header {
  margin: 2rem;
  font-size: 4.5rem;
  line-height: 1.2em;
  font-weight: lighter;
  
  @include mq('tablet') {
    margin-top: 7rem;
    margin-left: 12rem;
  }
}