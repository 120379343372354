.back-btn {
  font-size: 1.8rem;
  line-height: 2.2rem;
  color: #4d4d4d;
  border: none;
  background: none;
  cursor: pointer;
  font-weight: lighter;
  align-self: center;

  &:hover {
    text-decoration: underline;
  }
}

.reset-btn {
  @extend .back-btn;
  color: #0665BD;
}

.next-btn {
  width: 14.5rem;
  height: 4.5rem;
  background: #0665BD;
  border-radius: 3rem;
  color: $white;
  font-size: 1.8rem;
  font-weight: lighter;
  justify-self: end;
  outline: none;
  cursor: pointer;
  align-self: center;
  margin-right: 0;
  border: none;
  -moz-box-shadow: 1px 1px 3px 1px rgba(146, 145, 145, 0.75);
  -webkit-box-shadow: 1px 1px 3px 1px rgba(146, 145, 145, 0.75);
  box-shadow: 1px 1px 3px 1px rgba(146, 145, 145, 0.75);
  
  @include mq("smTablet") {
    margin-right: 50px;
  }

  &:hover, &:active {
    background: darken(#0665BD, 5%);
  }
}

.disabled {
  background: rgb(216, 216, 216);
  color: black;
  cursor: no-drop;

  &:hover, &:active {
    background: grey;
    color: black;
  }
}

.skip {
  margin: 2rem;
  text-align: right;

  @include mq('smTablet') {
    margin: 2rem 18rem;
  }
  
  & a {
    color: #949494;
    text-decoration: none;
  }
}

.cta-btn {
  background: $white;
  color: $tranont-primary;
  text-decoration: none;
  padding: 1rem 3rem;
  border-radius: 3rem;
  margin: 4rem auto;
  display: block;
  width: fit-content;
  width: -moz-fit-content;
  border: 2px solid #0665bd00;

  &:hover {
    background: lighten($tranont-primary, 10%);
    color: $white;
  }

  @include mq('tablet') {
    margin: 7rem 0;
  }
}

.header-btn {
  @extend .cta-btn;
  background: $tranont-primary;
  color: $white;

  @include mq('desktop') {
    -moz-box-shadow: 2px 2px 5px 1px rgba(146, 145, 145, 0.75);
    -webkit-box-shadow: 2px 2px 5px 1px rgba(146, 145, 145, 0.75);
    box-shadow: 2px 2px 5px 1px rgba(146, 145, 145, 0.75);

    &:hover {
      background: lighten($tranont-primary, 5%);
      color: $white;
    }
  }
}

.center-btn {
  margin: 7rem auto;
  display: block;
  width: fit-content;
}

.modify-btn {
  width: 100%;
  padding: 1rem 0;
  text-align: center;
  background: #00B7C2;
  cursor: pointer;
  color: white;
}