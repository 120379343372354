.results-header {
  display: grid;
  margin-bottom: 5rem;
  border-bottom: 1px solid #E6E6E6;
  align-items: center;
  grid-template-columns: 1fr;
  padding: 1rem;
  
  @include mq('smTablet') {
    grid-template-columns: 1fr 1fr;
    padding: 1rem 5rem;
  }

  & img {
    width: 22rem;
    margin: 0 auto;

    @include mq('tablet') {
      margin: 0;
    }
  }

  &__cta {
    justify-self: end;
    display: grid;
    grid-template-columns: 15rem 15rem;
    
    @include mq('smTablet') {
      gap: 0 2rem;
    }

    & p {
      font-size: 1.4rem;
      line-height: 1.8rem;
    }

    &--button {
      background: #0665BD;
      border-radius: 3rem;
      height: fit-content;
      text-align: center;
      padding: 1rem;
      align-self: center;
      color: #fff;
      text-decoration: none;

      &:hover {
        background: lighten(#0665BD, 5%);
      }
    }
  }
}

.results {
  display: grid;
  grid-template-columns: 1fr;
  margin: 2rem;
  justify-items: center;
  
  @include mq('tablet') {
    gap: 0 6rem;
    margin: 0 6rem;
    grid-template-columns: 1fr 3fr;
  }
  
  @include mq('desktop') {
    margin: 0 12rem;
  }


  &__inputs {
    padding: 1rem;
    border-radius: .5rem;
    height: fit-content;
    height: -moz-fit-content;
    margin-top: 2rem;
    
    @include mq('smTablet') {
      padding: 3rem;
      -moz-box-shadow: -2px 2px 5px 7px rgba(179,179,179,0.66);
      -webkit-box-shadow: -2px 2px 5px 7px rgba(179,179,179,0.66);
      box-shadow: -2px 2px 5px 7px rgba(179,179,179,0.66);
    }

    @include mq('tablet') {
      grid-row: 1;
    }
  }

  &__section-header {
    margin-top: 4rem;

    & p {
      margin: 0 2rem;
      line-height: 1em;
      display: inline-block;

    }
  }

  &__outcomes {
    grid-row: 1;

    @include mq('tablet') {
      grid-row: unset;
      min-width: 100%;
    }

    &--description {
      margin-top: 7rem;
    }
  }
}

.results-dial {
  position: relative;
  width: 28rem;
  height: 20rem;
  margin: 7rem auto;
  
  @include mq('smTablet') {
    width: 38rem;
    height: 30rem;
  }

  &__outer {
    position: absolute;
    
    @include mq('smTablet') {
      top: -28px;
      left: -75px;
      height: 285px;
      width: 518px;
    }
  }
  
  &__gauge {
    position: absolute;
    z-index: 1;
    width: 16rem;
    left: 6rem;
    top: 3rem;
    
    @include mq('smTablet') {
      height: 287px;
      width: 278px;
      top: 10%;
      left: 13%;
    }
  }

  &__score {
    position: absolute;
    top: 76%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;

    @include mq('smTablet') {
      top: 60%;
    }

    &--result {
      font-size: 5.4rem;
      color: #0665BD;
      font-weight: bold;
      font-family: $heading-font;
      display: block;
      text-align: center;
      
      @include mq('smTablet') {
        font-size: 6.4rem;
      }
    }

    &--label {
      font-size: 1.6rem;
      color: rgb(70, 70, 70);
      line-height: 1.8rem;
      font-weight: normal;
      text-align: center;
      display: block;
      margin-top: 4rem;

      @include mq('smTablet') {
        margin-top: 1rem;
      }
    }
  }
}

.results-widgets {
  display: grid;
  gap: 1.5rem;
  margin: 3rem auto;
  justify-content: space-around;
  grid-template-columns: repeat(auto-fit, minmax(27rem, auto));
  
  &__container {
    border: 2px solid #E4F5FF;
    border-radius: .5rem;
    width: 90%;
    height: 7rem;
    display: grid;
    padding: 0 1rem;
    position: relative;

    &--result{
      position: absolute;
      top: 2rem;
      left: 8rem;
      font-size: 2.2rem;
      line-height: 2.5rem;
    }

    .svg-meter {
      position: absolute;
      left: 10px;
    }

    .icon {
      position: absolute;
      left: 2.5rem;
      top: 23px;
      width: 2.7rem;
    }

    .small-label {
      font-size: 1.4rem;
      position: absolute;
      left: 8rem;
      top: 1.5rem;
      line-height: 1.6rem;
      margin: 0;
    }
  }

}

.percentage {
  fill: #666;
  font-family: sans-serif;
  font-size: 0.5em;
  text-anchor: middle;
}

.circle-bg {
  fill: none;
  stroke: #eee;
  stroke-width: 3.8;
}

.svg-meter{
  display: block;
  max-width: 5.5rem;
  max-height: 5.5rem;
  align-self: center;
}

.svg-meter.green .circle {
  stroke: #4CC790;
}

.svg-meter.orange .circle {
  stroke: #E97442;
}

.svg-meter.light-blue .circle {
  stroke: #00b8c3;
}

.svg-meter.blue .circle {
  stroke: #0665BD;
  font-weight: bold;
}

.blue-text {
  color: #0665BD;
  font-weight: bold;
}

.light-blue-text {
  color: #00b8c3;
  font-weight: bold;
}

.orange-text {
  color: #E97442;
  font-weight: bold;
}

.circle {
  fill: none;
  stroke-width: 2.8;
  stroke-linecap: round;
  animation: progress 1s ease-out forwards;
}

.blue-label {
  font-weight: bold;
  color: $tranont-primary;
  line-height: 1em;
  font-size: 2.7rem;
}

.results-title {
  font-size: 2rem;
  line-height: 1.2rem;
  margin: 0;
  font-weight: lighter;
}

.MuiOutlinedInput-input {
  padding: 14px !important;
}

.results-cta {
  margin: 10rem 0;

  &__hero, &__hero-2 {
    background: linear-gradient(83.31deg, #00B7C2 1.46%, #0665BD 95.8%);
    padding: 5rem;
    height: 28rem;
    
    @include mq('tablet') {
      height: 12rem;
    }

    & h4 {
      color: #fff;
      text-align: center;
      font-weight: lighter;
      font-size: 2.4rem;
    }
  }

  &__hero-2 {
    height: 20rem;
    margin-bottom: -10rem;
  }

  &__content {
    margin: 2rem;
    
    @include mq('smTablet') {
      margin: 12rem;
    }

    & h2 {
      font-size: 3.4rem;
      font-weight: lighter;
      line-height: 4.2rem;
    }

    &--grid {
      display: grid;
      grid-template-columns: 1fr;
      margin: 4rem 0;
      
      @include mq('tablet') {
        grid-template-columns: 1fr 2fr;
      }

      & h4 {
        font-size: 2rem;
        margin: 1rem 0;

        @include mq('tablet') {
          margin: 0;
        }
      }

      & p {
        margin: 0;
      }
    }

    &--disclaimer {
      margin-top: 10rem;
      font-size: 1.4rem;
      color: rgb(99, 99, 99);

      & h4 {
        color: rgb(70, 70, 70);
      }
    }
  }
}

.col-label {
  margin-top: 2rem;
}

.col-toggle {
  width: fit-content;
  width: -moz-fit-content;
  margin: 0 auto 0;
  
  & span {
    font-size: 1.5rem;
  }
}

// .red-border {
//   border: 1px solid #f44335;
// }

.attention {
  color: #fff;
  font-size: 1.2rem;
  background: #f44335;
  position: absolute;
  top: -1rem;
  right: -1rem;
  width: 2rem;
  height: 2rem;
  text-align: center;
  border-radius: 5rem;
  line-height: 2rem;
  text-decoration: none;

  &:hover {
    background: darken(#f44335, 5%);
  }
}

.alert-message {
  position: relative;
  padding: 1rem 1.5rem;
  border: 1px solid #f44335;
  border-radius: 1rem;
  margin: 2rem 1rem;
  
  @include mq('smTablet') {
    padding: 1rem 2.5rem;
    margin: 2rem;
  }

  &__icon {
    color: #fff;
    font-size: 1.2rem;
    background: #f44335;
    position: absolute;
    top: -.75rem;
    left: -1rem;
    width: 2rem;
    height: 2rem;
    text-align: center;
    border-radius: 5rem;
    line-height: 2rem;
  }
}

.loading {
  margin-top: 15rem;
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;

  & h3 {
    color: $tranont-primary;
  }

  & img {
    width: 150px;
  }
}

.actions-container {
  display: grid;
  grid-template-columns: 1.8rem 1fr 2.3rem;
  gap: 1rem;
  align-items: center;

  & svg {
    justify-self: center;
    fill: #0665BD;
    cursor: pointer;
  }
}

.results-inline-input {
  border: none;
  border-bottom: 1px solid #ccc;
  margin: 0 5px;
  font-size: 1.8rem;
  padding: .3rem;
  text-align: center;
  color: #0665BD;
  font-weight: bold;

  &:focus {
    outline: none;
  }
}

.results-input-label {
  font-size: 1.8rem;
  margin-bottom: 3rem;
}