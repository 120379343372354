.quiz {
  display: grid;
  grid-template-columns: 1fr;
  height: 105vh;
  
  @include mq('smTablet') {
    height: 80rem;
  }
  
  @include mq('tablet') {
    grid-template-columns: 1fr 1fr;
  }

  &__background {
    position: relative;
    z-index: 1;

    & .mobile {
      display: block;
      
      @include mq('smTablet') {
        display: none;
      }
    }

    & .desktop {
      display: none;

      @include mq('smTablet') {
        display: block;
      }
    }

    &--logo {
      position: absolute;
      left: 50%;
      transform: translate(-50%);
      z-index: 2;
      width: 27rem;
      top: 1rem;
      
      @include mq('smTablet') {
        top: 5rem;
        left: 5rem;
        transform: none;
      }

      @include mq('tablet') {
        left: 13rem;
      }
    }

    &--layer-1 {
      position: absolute;
      width: 32rem;
      top: 27rem;
      
      @include mq('smTablet') {
        top: 32rem;
        width: 42rem;
      }
      
      @include mq('tablet') {
        width: 88.5rem;
        top: 0;
      }      
    }
    
    &--layer-2 {
      position: absolute;
      width: 27rem;
      
      @include mq('smTablet') {
        width: 61rem;
      }

      @include mq('tablet') {

      }      
    }
    
    &--layer-3 {
      position: absolute;
      display: none;
      
      @include mq('smTablet') {        
        display: block;
        left: 30rem;
        top: 20rem;
      }
      
      @include mq('tablet') {        
      }      
    }
    
    &--layer-4 {
      position: absolute;
      display: none;
      grid-template-columns: repeat(10, 1fr);
      gap: 3.5rem;
      top: 16rem;
      left: 13rem;
      
      
      @include mq('smTablet') {
        display: grid;
      }

      @include mq('tablet') {

      }      
    }
  }

  &__content {
    display: grid;
    grid-template-rows: 12rem 21rem 10rem;
    z-index: 2;
    align-self: center;
    margin-left: auto;
    margin-right: auto;
    background: #ffffffe8;
    padding: 2rem;
    border-radius: 3rem;
    box-shadow: 0px 4px 36px rgba(0, 0, 0, 0.12);
    margin-top: 0;
    width: 70%;
    position: absolute;
    top: 12rem;
    left: 50%;
    transform: translate(-50%);
    
    @include mq('smTablet') {
      max-width: 60rem;
      width: unset;
      // margin-top: -5rem;
      grid-template-rows: 8rem 24rem 15rem;
    }
    
    @include mq('tablet') {
      box-shadow: unset;
      background: none;
      padding: 0;
      border-radius: unset;
      position: unset;
      transform: unset;
    }

    & p {
      font-weight: lighter;
    }

    .form-container {
      align-self: center;
    }

    .step {
      font-size: 1.6rem;
      margin: 0;
    }
  }
}