/// Tranont Colors

$tranont-primary: #0665BD;
$black: rgb(0,0,0);
$white: rgb(255, 255, 255);

$tranont-secondary: rgb(82, 93, 255);
$tranont-green: rgb(41, 198, 14);
$tranont-orange: rgb(255, 148, 6);

$tranont-tertiary: rgb(255, 26, 26);
$tranont-purple: rgb(145, 56, 249);
$tranont-grey: rgb(119, 119, 122);