.footer {
  color: #b5b5b5;
  padding: 2rem 0;
  -webkit-box-shadow: 0px -8px 5px 0px rgba(224,224,224,.5);
  -moz-box-shadow: 0px -8px 5px 0px rgba(224,224,224,.5);
  box-shadow: 0px -8px 5px 0px rgba(224,224,224,.5);
  
  &__content {
    text-align: center;
    width: 80vw;
    margin: 0 auto;
    font-size: 1.4rem;
  }

  &__powered_by {
    width: fit-content;
    width: -moz-fit-content;
    font-size: 1.2rem;
    color: black;
    margin: 2rem auto;
    
    // @include mq('tablet') {      
    //   display: flex;
    //   justify-content: flex-end;
    //   margin: 2rem;
    // }

    & div {
      font-size: 1.2rem;
    }

    & img {
      width: 14rem;
    }
  }
}