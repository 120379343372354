$heading-font: 'Montserrat', sans-serif;
$body-font: 'Lato', sans-serif;


div, p {
  font-family: $body-font;
  font-size: 1.8rem;
  font-weight: 400;
}

h1 {
  font-family: $heading-font;
  font-size: 10rem;
  font-weight: 800;

  @include mq("smDesktop") {
    font-size: 8rem;
  }

  @include mq("tablet") {
    font-size: 6rem;
  }

}

h2 {
  font-family: $heading-font;
  font-size: 7rem;
  font-weight: 800;
  line-height: 8.5rem;
  
  @include mq("smDesktop") {
    font-size: 6.5rem;
    line-height: 6.5rem;
  }

  @include mq("tablet") {
    font-size: 5rem;
  }

}

h3 {
  font-family: $body-font;
  font-size: 3rem;
  font-weight: 900;

  @include mq("tablet") {
    font-size: 2.5rem;
    font-weight: 400;
    line-height: 3.5rem;
  }

  @include mq("tablet") {
    font-size: 2rem;
    line-height: 2.5rem;
  }
}

h4 {
  font-family: $body-font;
  font-size: 2.5rem;
  font-weight: 700;

  @include mq("smTablet") {
    font-size: 2rem;
  }
}

h6 {
  font-family: $body-font;
  font-size: 2.7rem;
  font-weight: 300;
}

.blue-text {
  color: darken($tranont-primary, 5%);
}

.lighter {
  font-weight: 300;
}

.bold {
  font-weight: bold;
}

.text-small {
  font-size: 1.4rem;
}

.font-ex-small {
  font-size: 1.4rem;
  font-weight: 300;
}