@import url('https://fonts.googleapis.com/css?family=Lato:300,400,400i,700,900|Montserrat:400,500,800,800i&display=swap');

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
}

html {
  font-size: 62.5%;
}

body {
  height: 100%;
  position: relative;
}

p {
  margin: 1.5rem 0;
  font-size: 1.8rem;
  line-height: 2.8rem;
}

ol ul {
  list-style-type: initial;
  margin-block-start: 0px;
  margin-block-end: 0px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}