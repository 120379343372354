.prequiz {
  & label {
    font-weight: lighter;
    font-size: 2rem;
    font-family: $heading-font;
    display: block;
  }

  & input {
    margin-top: 4rem;
    margin-left: auto;
    margin-right: auto;
    display: block;
    width: 85%;
    height: 3rem;
    border: 1px solid #E6E6E6;
    box-sizing: border-box;
    border-radius: .7rem;
    padding: 2.5rem 1rem;
    font-size: 1.8rem;
    -webkit-box-shadow: 3px 6px 5px 0px rgba(219,219,219,1);
    -moz-box-shadow: 3px 6px 5px 0px rgba(219,219,219,1);
    box-shadow: 3px 6px 5px 0px rgba(219,219,219,1);
    outline: none;

    @include mq('smTablet') {
      width: 47rem;
      margin-left: 0;
      margin-right: 0;
      display: initial;
    }
    
    @include mq('tablet') {
      width: 52rem;
    }
  }

}
.form-controls {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.inset-icon {
  display: inline-block;
  cursor: pointer;
  position: absolute;
  bottom: 1.2rem;
  right: 3.5rem;

  @include mq('tablet') {
    right: 9rem;
  }
}

.input-box {
  position: relative;
}

.help-msg {
  position: absolute;
  bottom: 6rem;
  right: 1.5rem;
  width: 25rem;
  background: white;
  padding: .5rem;
  border-radius: 1rem;
  text-align: center;
  -webkit-box-shadow: 3px 6px 5px 0px rgba(219,219,219,1);
  -moz-box-shadow: 3px 6px 5px 0px rgba(219,219,219,1);
  box-shadow: 3px 6px 5px 0px rgba(219,219,219,1);
  border: 1px solid rgba(219,219,219,1);
  font-size: 1.4rem;
  font-weight: lighter;

  @include mq('tablet') {
    right: 8.5rem;
    width: 30rem;
  }
}

.opacity-hidden {
  color: #00000000;
}